<template>
    <div>
        <main-header></main-header>
        <breadcrumbs></breadcrumbs>
        <div class="quicksearch-overlay" v-on:click="closeOverlay()"  v-if="this.$store.state.quickSearchOverlay"></div>
        <div class="wrapper">

            <div class="carfinance">
                <div class="carfinance__top">
                    <h1>Terrific.ie <span class="green">Car Finance</span></h1>
                    <div class="carfinance__top_columns">
                        <div class="carfinance__top_left">
                            <div class="carfinance__top_image carfinance__top_image--small"></div>
                            <div class="carfinance__top_image carfinance__top_image--large"></div>
                        </div>
                        <div class="carfinance__top_right">
                            <quick-search :monthlyPrice="true"></quick-search>
                        </div>
                    </div>
                </div>
                <div class="carfinance__body">
                    <div class="carfinance__body_col">
                        <h2>Super quick and easy car finance</h2>
                        <p>Buying a new or used car is likely one of the most significant investments that you’re likely to make.
                            For most people, paying for the vehicle up front isn’t a realistic option. When that’s the case,
                            acquiring the car by way of finance can be a way of getting the vehicle you want, without having to
                            worry about an unmanageable upfront investment.</p>
                        <p>
                            We’ve designed Terrific.ie to be as useful for shoppers who wish to buy their next car using finance
                            or cash! We have built dynamic search filters that allow you to browse for your next car with a
                            monthly budget or overall cash amount.
                        </p>
                        <h3>What is car finance?</h3>
                        <p>Put simply, car finance provides customers with a way to buy or hire a new car without having to pay
                            the full cash amount up front. There are a few different types of car finance but the most common
                            types, particularly in Ireland are Hire Purchase (HP) and Personal Contract Purchase (PCP).</p>
                        <p>
                            Both types above, require a customer to pay a small up-front deposit, usually 10% of the cost price
                            of the vehicle, and then a series of monthly repayments which depend on how long you would like
                            your agreement to last. Find out more about HP and PCP below.
                        </p>
                    </div>
                    <div class="carfinance__body_col">
                        <h3>HP Explained</h3>
                        <p>HP is the most popular form of car finance in Ireland, particularly when it comes to used cars. A HP
                            agreement normally involves you paying an initial deposit and then a series of monthly repayments.
                            HP agreements typically last for a duration of between 3 and 5 years. The deposit and term are
                            generally flexible and are both decided by you.</p>
                        <p>
                            The monthly payments are worked out by looking at the purchase price of the car, taking off your
                            deposit (normally in the form of a trade-in of an older car), adding on the interest amount the dealer
                            will be charging and then dividing the entire amount by the number of months duration of the
                            agreement. This gives you a fixed monthly payment.
                        </p>
                        <p>
                            At the end of the agreed period, you will have paid for the car in full plus the specified interest. With
                            this option you will own the car in full at the end of the agreement. The benefits of purchasing a car
                            on Hire Purchase or HP are the facts that you will own the car at the end of the agreement
                            (assuming you have made all the payments) and its easier to plan for financially as it’s a fixed
                            monthly payment and you don’t need to be concerned with extra charges etc should you go over
                            your agreed mileage over the term of the deal.
                        </p>
                        <p>
                            To find out more about both types of finance, view our HP and PCP case studies below.
                        </p>
                    </div>
                    <div class="carfinance__body_col">
                        <h3>PCP Explained</h3>
                        <p>
                            PCP is typically the second most popular form of car finance in Ireland and tends to have a higher
                            uptake amongst new car buyers.
                        </p>
                        <p>
                            The concept behind PCP is similar to HP, you pay a deposit and fixed amount every month for the
                            duration of the agreement, but the difference is that with PCP finance a large chunk of the purchase
                            price of the car is taken off and reserved for the final payment of the agreement, this is known as the
                            guaranteed minimum future value or GMFV. The GMFV should be a fair estimation of what the
                            vehicle will be worth at the end of your agreement and will be agreed by you and the car dealer
                            before you sign the agreement.
                        </p>
                        <p class="strong">
                            With PCP, you have a few options at the end of the agreement.
                        </p>
                        <ol>
                            <li>
                                You can pay off the remainder of the purchase price and take ownership of the vehicle.
                            </li>
                            <li>
                                You can hand the keys back to the dealer.
                            </li>
                            <li>
                                If at the end of your agreement your vehicle is worth more than the initial GMFV you agreed with
                                your dealer, you can use the difference between the vehicles existing worth and the GMFV as a
                                deposit towards a new agreement.
                            </li>
                        </ol>
                        <p>
                            To find out more about both types of finance, view our PCP and HP case studies below.
                        </p>
                    </div>
                </div>
                <div class="carfinance__body faq">
                    <div class="carfinance__body_col">
                        <h3>Frequently Asked Questions</h3>
                        <h4>
                            Which is better, HP or PCP ?
                        </h4>
                        <p>
                            This really depends on personal preference. Your dealer will be able to talk you through all the pros
                            and cons with regards to your personal situation. Our PCP and HP case studies should paint a clearer
                            picture on how personal preferences can affect the type of finance that are most suitable for you.
                        </p>
                        <h4>
                            What are the finance rates?
                        </h4>
                        <p>
                            Car dealers will work with several lenders who offer alternative rates. Where a vehicle is advertised
                            on Terrific.ie with a monthly rental, the APR will also be displayed along with a finance breakdown.
                            The APR’s are typically very competitive versus a traditional bank loan.
                        </p>
                        <h4>
                            Are there any other options to finance my car?
                        </h4>
                        <p>
                            Hire purchase and Personal Contract Plans are the most popular options to finance the buying of a
                            car, but you can also investigate taking out a loan from your bank, building society or credit union.
                        </p>
                        <h4>
                            How fast does the finance process take?
                        </h4>
                        <p>
                            One of the advantages to car finance is that the process is generally much faster than alternatives
                            like a traditional car loan. Our finance partner review applications from Terrific.ie in as little as 3
                            hours. You could be driving your new car away within the next working day.
                        </p>
                        <h4>
                            Which is better, HP or PCP ?
                        </h4>
                        <p>
                            This really depends on personal preference. Your dealer will be able to talk you through all the pros
                            and cons with regards to your personal situation. Our PCP and HP case studies should paint a clearer
                            picture on how personal preferences can affect the type of finance that are most suitable for you.
                        </p>
                        <h4>
                            What are the finance rates?
                        </h4>
                        <p>
                            Car dealers will work with several lenders who offer alternative rates. Where a vehicle is advertised
                            on Terrific.ie with a monthly rental, the APR will also be displayed along with a finance breakdown.
                            The APR’s are typically very competitive versus a traditional bank loan.
                        </p>
                        <h4>
                            Are there any other options to finance my car?
                        </h4>
                        <p>
                            Hire purchase and Personal Contract Plans are the most popular options to finance the buying of a
                            car, but you can also investigate taking out a loan from your bank, building society or credit union.
                        </p>
                        <h4>
                            How fast is the finance process?
                        </h4>
                        <p>
                            One of the advantages to car finance is that the process is generally much faster than alternatives
                            like a traditional car loan. Our finance partner review applications from Terrific.ie in as little as 3
                            hours. You could be driving your new car away within the next working day.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>

    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import QuickSearch from '@/components/QuickSearch'
    import Breadcrumbs from '@/components/partials/Breadcrumbs'

    export default {
        name: "CarFinance",
        components: {
            MainHeader,
            MainFooter,
            QuickSearch,
            Breadcrumbs
        },
        data: function () {
            return {
                title: "Car Finance, Apply for Car Finance, Dealers offering Finance, Ireland | Terrific.ie",
                description: "Car finance options in Ireland and allows users apply for car finance with car dealers.",
                keywords: "Car Finance, Apply for Car Finance, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters,  Land Rover, People Carriers",
                robots: "all"
            }
        },
        metaInfo() {
            return {
                title: this.title,
            };
        },
        serverPrefetch () {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
        },
        methods:{
            closeOverlay(){
                this.$store.state.quickSearchOverlay = false;
            }
        }
    }
</script>

<style scoped>

</style>
